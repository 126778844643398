import React from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton";

const BackBtnWithPageTitle = ({ title }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center gap-5">
      <CustomButton
        onClick={() => navigate(-1)}
        className={"flex items-center gap-2"}
      >
        <FaChevronLeft className="m-0" /> Back
      </CustomButton>

      {title && <h4 className="mb-0 uppercase">{title}</h4>}
    </div>
  );
};

export default BackBtnWithPageTitle;
