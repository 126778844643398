import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaAnglesLeft, FaAnglesRight, FaCloud, FaMinus } from "react-icons/fa6";
import classNames from "classnames";
import menus from "../../helpers/constants/menus";
import CustomSidebarCollapse from "./CustomSidebarCollapse";
import { useSelector } from "react-redux";
import { useGetStorage } from "../componentQueries";

const CustomSidebar = ({ parentPath }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const { data: storage } = useGetStorage();
  const extractNumber = (str) => parseFloat(str.replace(/[^0-9.]/g, ""));
  const usedStorage = storage?.data?.used
    ? extractNumber(storage.data.used)
    : 0;
  const totalStorage = storage?.data?.total
    ? extractNumber(storage.data.total)
    : 1;
  const storagePercentage = (usedStorage / totalStorage) * 100;

  const isAuthorizedToAccessMenu = (menuPermission) =>
    user?.userRole?.rolePermissions?.find(
      (permission) =>
        permission?.RolePermission?.permissionId === menuPermission
    );

  const renderNestedChildMenus = (menu) =>
    menu.children.map(
      (childMenu, idx) =>
        isAuthorizedToAccessMenu(childMenu?.permission) && (
          <Link
            key={`${menu?.title}-sidemenu-nested-child-menu-${idx}`}
            className={classNames(
              "text-gray-400 font-light p-3 hover:text-gray-300 w-full flex items-center gap-3 pl-7 text-sm",
              parentPath === childMenu.link && "text-white"
            )}
            to={childMenu.link}
          >
            <FaMinus className="text-sm" />
            {childMenu.title}
          </Link>
        )
    );

  const renderChildMenus = (menu) =>
    menu.children.map((childMenu, idx) =>
      childMenu?.children
        ? isAuthorizedToAccessMenu(childMenu?.permission) && (
            <div className="pl-3 text-sm">
              <CustomSidebarCollapse
                className={"text-sm"}
                renderChildMenus={renderNestedChildMenus}
                parentPath={parentPath}
                menu={childMenu}
                key={`parent-link-child-side-menu-${idx}`}
                isCollapsed={isCollapsed}
              />
            </div>
          )
        : isAuthorizedToAccessMenu(childMenu?.permission) && (
            <Link
              key={`${menu?.title}-sidemenu-child-menu-${idx}`}
              className={classNames(
                "text-gray-400 font-light p-3 hover:text-gray-300 w-full flex items-center gap-3 pl-7 text-sm",
                parentPath === childMenu.link && "text-white"
              )}
              to={childMenu.link}
            >
              <FaMinus className="text-sm" />
              {childMenu.title}
            </Link>
          )
    );

  return (
    <div
      className={classNames(
        "bg-main relative border-r border-white h-screen flex flex-col",
        isCollapsed ? "w-fit" : "min-w-[300px] max-w-fit"
      )}
    >
      <div className="sticky top-0">
        <Link to={"/dashboard"}>
          <img
            src="/images/logo.png"
            alt=""
            className={classNames(
              "W-16 h-16 object-scale-down m-1 mx-auto"
              // isCollapsed ? "invisible" : "block"
            )}
          />
        </Link>

        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="text-white absolute top-2 right-2 rounded-full w-9 h-9 bg-gray-600/50 border border-gray-400 flex items-center justify-center"
        >
          {isCollapsed ? (
            <FaAnglesRight className="text-xl" />
          ) : (
            <FaAnglesLeft className="text-xl" />
          )}
        </button>
      </div>

      <div className="mt-5 flex-1 overflow-auto sidebar hover:scrollbar-thin">
        {menus.map((menu, idx) =>
          menu.children
            ? isAuthorizedToAccessMenu(menu?.permission) && (
                <CustomSidebarCollapse
                  renderChildMenus={renderChildMenus}
                  parentPath={parentPath}
                  menu={menu}
                  key={`parent-link-side-menu-${idx}`}
                  isCollapsed={isCollapsed}
                />
              )
            : isAuthorizedToAccessMenu(menu?.permission) && (
                <Link
                  key={`parent-link-side-menu-${idx}`}
                  className={classNames(
                    "text-gray-400 font-light p-3 hover:text-gray-300 w-full flex items-center text-l gap-3 text-lg",
                    menu.link === parentPath && "text-white"
                  )}
                  to={menu.link}
                >
                  {menu.icon} {!isCollapsed && menu.title}
                </Link>
              )
        )}
      </div>

      <div
        className={`sticky bottom-0 bg-[#100160] p-3 ${
          isCollapsed && "hidden"
        }`}
      >
        <div className="bg-blue-500 p-4 rounded-lg shadow-lg text-white">
          <div className="flex items-center mb-2">
            <FaCloud className="mr-2 text-xl" />
            <div className="font-bold">Your Storage</div>
          </div>
          <div className="h-2 bg-gray-300 rounded-full mb-2">
            <div
              className={`h-2 bg-white rounded-full border-r border-[#100160]`}
              style={{ width: `${Math.round(storagePercentage)}%` }}
            ></div>
          </div>
          <div className="text-sm">
            You have used {storage?.data?.used} out of {storage?.data?.total}.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSidebar;
