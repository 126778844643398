import React, { Suspense } from "react";
import MainLayout from "./components/layout/MainLayout";
import routes from "./routes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import "react-responsive-modal/styles.css";
import "react-quill/dist/quill.snow.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { v4 as uuidV4 } from "uuid";
import CustomSpinner from "./components/generic/CustomSpinner";
import PageWrapper from "./components/layout/PageWrapper";
import { useSelector } from "react-redux";
import { IncidentContextProvider } from "./context/IncidentContext";
import { EditIncidentContextProvider } from "./context/EditIncidentContext";

import { GlobalWorkerOptions } from "pdfjs-dist/build/pdf.mjs";

// Set the workerSrc to the local path of the pdf.worker.min.js file
GlobalWorkerOptions.workerSrc = "/scripts/pdf.worker.min.js";

const App = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-screen w-screen">
            <img
              src="/images/logo.png"
              className="w-[150px] h-[150px] object-scale-down"
              alt=""
            />
          </div>
        }
      >
        <IncidentContextProvider>
          <EditIncidentContextProvider>
            <Routes>
              {routes.map((route) => (
                <Route
                  key={uuidV4()}
                  path={route.path}
                  element={
                    route.isProtected ? (
                      <MainLayout
                        title={route.title}
                        parentPath={route.parentPath}
                      >
                        {route.element}
                      </MainLayout>
                    ) : (
                      <PageWrapper title={route.title}>
                        {route.element}
                      </PageWrapper>
                    )
                  }
                />
              ))}
            </Routes>
          </EditIncidentContextProvider>
        </IncidentContextProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
