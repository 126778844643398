import PDFIcon from "../assets/icons/pdf.png";
import DocxIcon from "../assets/icons/word.png";
import XlsIcon from "../assets/icons/excel.png";
import PptIcon from "../assets/icons/powerpoint.png";
import CsvIcon from "../assets/icons/csv.png";
import TextIcon from "../assets/icons/text.png";
import SQLIcon from "../assets/icons/sql.png";
import ImageIcon from "../assets/icons/image.png";
import ZipIcon from "../assets/icons/zip.png";
import { twMerge } from "tailwind-merge";

const iconStyles = "block w-full mx-auto object-scale-down";
const findFileIcon = (file, className = "") => {
  const fileName = file?.name;

  if (fileName?.includes(".pdf")) {
    return (
      <img src={PDFIcon} className={twMerge(iconStyles, className)} alt="PDF" />
    );
  } else if (fileName?.includes(".docx") || fileName?.includes(".doc")) {
    return (
      <img
        src={DocxIcon}
        className={twMerge(iconStyles, className)}
        alt="DOCX"
      />
    );
  } else if (
    fileName?.includes(".xlsx") ||
    fileName?.includes(".xls") ||
    fileName?.includes(".xsl")
  ) {
    return (
      <img
        src={XlsIcon}
        className={twMerge(iconStyles, className)}
        alt="XLSX"
      />
    );
  } else if (fileName?.includes(".csv")) {
    return (
      <img src={CsvIcon} className={twMerge(iconStyles, className)} alt="CSV" />
    );
  } else if (fileName?.includes(".ppt") || fileName?.includes(".pptx")) {
    return (
      <img src={PptIcon} className={twMerge(iconStyles, className)} alt="PPT" />
    );
  } else if (fileName?.includes(".sql")) {
    return (
      <img src={SQLIcon} className={twMerge(iconStyles, className)} alt="SQL" />
    );
  } else if (fileName?.includes(".zip")) {
    return (
      <img src={ZipIcon} className={twMerge(iconStyles, className)} alt="ZIP" />
    );
  } else if (fileName?.match(/\.(jpeg|jpg|gif|png)$/)) {
    return (
      <img src={ImageIcon} className={twMerge(iconStyles, className)} alt="" />
    );
  } else {
    return (
      <img
        src={TextIcon}
        className={twMerge(iconStyles, className)}
        alt="Text"
      />
    );
  }
};

export default findFileIcon;
