import { useMutation, useQuery } from "@tanstack/react-query";
import axiosConfig from "../../../http-common";
import { getFileApplicationType } from "../../../helpers/helperFunctions";


export const useGetMissions = () => useQuery({
  queryKey: ["getMissions"],
  queryFn: async () => await axiosConfig.get(`/mission`)
})


export const useGetSingleMission = (id) => useQuery({
  queryKey: ["getSingleMission", { id }],
  queryFn: async () => await axiosConfig.get(`/mission/${id}`),
  enabled: !!id
})


export const useCreateMission = () => useMutation({
  mutationKey: ["createMission"],
  mutationFn: async (formData) => await axiosConfig.post("/mission/assignGroupsToMission", formData)
})


export const useUpdateMission = () => useMutation({
  mutationKey: ["updateMission"],
  mutationFn: async (formData) => await axiosConfig.put(`/mission/${formData?.id}`, formData)
})


export const useDeleteMission = () => useMutation({
  mutationKey: ["deleteMission"],
  mutationFn: async (id) => await axiosConfig.delete(`/mission/${id}`)
})


export const useGetAllPriorityLevels = () => useQuery({
  retry: 1,
  queryKey: ['getAllPriorityLevels'],
  queryFn: async () => await axiosConfig.get("/priorityLevel")
})


export const useGetAllGroups = () => useQuery({
  queryKey: ["getAllGroups"],
  queryFn: async () => await axiosConfig.get("/group")
})




// comments
export const useAddCommentToMission = () => useMutation({
  mutationKey: ["addCommentToMission"],
  mutationFn: async (formData) => await axiosConfig.post("/missionComment", formData)
})



export const useDeleteMissionComment = () => useMutation({
  mutationKey: ["deleteMissionComment"],
  mutationFn: async (id) => await axiosConfig.delete(`/missionComment/${id}`)
})


// attachments

export const useUploadAttachment = () => useMutation({
  mutationKey: ["uploadAttachment"],
  mutationFn: async (formData) => await axiosConfig.post(`/mission/attachFilesToMission`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
})



const fetchBlob = async (fileId, missionId) => {
  const data = await axiosConfig.get(`/mission/${fileId}/readMissionAttachment/${missionId}`, {
    responseType: "blob",
  });

  const blob = new Blob([data], { type: getFileApplicationType() });
  return blob;
};


export const useReadFile = () => useMutation({
  mutationKey: ["readFile"],
  mutationFn: async ({ fileId, missionId }) => {
    try {
      const blob = await fetchBlob(fileId, missionId);
      const blobUrl = URL.createObjectURL(blob);
      return blobUrl
    } catch (err) {
      throw (err)
    }
  }
})


export const useDeleteMissionAttachment = () => useMutation({
  mutationKey: ["deleteMissionAttachment"],
  mutationFn: async (id) => await axiosConfig.delete(`/mission/deleteMissionAttachment/${id}`)
})