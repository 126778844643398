import Swal from "sweetalert2";
import { v4 } from "uuid";
import { mainColor } from "./constants/colors";
import toast from "react-hot-toast";
import ErrorGif from "../assets/images/error.gif";
import SuccessGif from "../assets/images/success.gif";
import { FaX } from "react-icons/fa6";
import { store } from "../redux/store";
import { logoutUserAction } from "../redux/slices/authSlice";
import { Country } from "country-state-city";
import menus from "./constants/menus";

export const uuidV4 = () => v4();

// File types

export const findFileKind = (file) => {
  const fileName = file?.name;

  if (fileName?.includes(".pdf")) {
    return "PDF File";
  } else if (fileName?.includes(".docx") || fileName?.includes(".doc")) {
    return "Word File";
  } else if (fileName?.includes(".xlsx") || fileName?.includes(".xls")) {
    return "Excel";
  } else if (fileName?.includes(".csv")) {
    return "CSV File";
  } else if (fileName?.includes(".ppt") || fileName?.includes(".pptx")) {
    return "Powerpoint File";
  } else if (fileName?.includes(".sql")) {
    return "SQL File";
  } else if (fileName?.includes(".zip")) {
    return "ZIP File";
  } else if (fileName?.match(/\.(jpeg|jpg|gif|png)$/)) {
    return "Image File";
  } else if (fileName?.match(/\.(mp4|avi|mkv)$/)) {
    return "Video file";
  } else return "Text file";
};

// file application type
export const getFileApplicationType = (fileName) => {
  if (fileName?.includes(".doc"))
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  else if (fileName?.includes(".xls"))
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  else if (fileName?.includes(".csv")) return "text/csv";
  else return "application/pdf";
};

// toast and alerts

export const confirmationSweetAlert = async () =>
  await Swal.fire({
    title: "Are you sure?",
    text: "Click yes to continue. Otherwise cancel.",
    confirmButtonColor: "#ff0000",
    confirmButtonText: "Yes",
    showConfirmButton: true,
    showCancelButton: true,
    cancelButtonColor: mainColor,
  });

export const errorSweetAlert = (message) => {
  Swal.fire({
    icon: "error",
    title: "Oops!",
    text: message || "Server error",
    confirmButtonColor: mainColor,
    confirmButtonText: "Okay",
  });
};

export const errorToast = (message, options = {}) => {
  toast(
    (t) => (
      <div className="w-[200px] flex flex-col gap-2">
        <div className="flex items-center gap-2 justify-between">
          <p className="text-xs font-semibold">Oops!</p>
          <button
            onClick={() => toast.dismiss(t.id)}
            className="bg-transparent p-2 border border-gray-300"
          >
            <FaX className="text-sm" />
          </button>
        </div>
        <div className="flex items-center gap-2 w-full">
          <img src={ErrorGif} alt="" className="w-[35px] object-scale-down" />
          <p className="flex-1 text-xs">{message}</p>
        </div>
      </div>
    ),
    {
      duration: 5000,
      style: {
        marginTop: 24,
      },
      ...options,
    }
  );
};

export const successToast = (message, options = {}) =>
  toast(
    (t) => (
      <div className="w-[200px] flex flex-col gap-2">
        <div className="flex items-center gap-2 justify-between">
          <p className="text-xs font-semibold">Yep!</p>
          <button
            onClick={() => toast.dismiss(t.id)}
            className="bg-transparent p-2 border border-gray-300"
          >
            <FaX className="text-sm" />
          </button>
        </div>
        <div className="flex items-center gap-2 w-full">
          <img src={SuccessGif} alt="" className="w-[35px] object-scale-down" />
          <p className="flex-1 text-xs">{message}</p>
        </div>
      </div>
    ),
    {
      duration: 5000,
      style: {
        marginTop: 24,
      },
      ...options,
    }
  );

// auth helper
export const logoutUser = (navigate) => {
  store.dispatch(logoutUserAction());
  navigate("/");
};

export const lockScreen = (navigate) => {
  const { user } = store.getState().auth;
  const userCopy = { ...user };
  store.dispatch(logoutUserAction());
  navigate("/lock-screen", {
    state: userCopy,
  });
};

export const isUserHavingTheRoutePermission = (path = "/") => {
  const { user } = store.getState().auth;

  let doesUserHavePermission = false;
  menus.forEach((menu) => {
    if (
      path.includes(menu?.link) &&
      user?.userRole?.rolePermissions?.find(
        (permission) =>
          permission?.RolePermission?.permissionId === menu?.permission
      )
    ) {
      doesUserHavePermission = true;
    } else {
      menu?.children?.forEach((childMenu) => {
        if (
          path.includes(childMenu?.link) &&
          user?.userRole?.rolePermissions?.find(
            (permission) =>
              permission?.RolePermission?.permissionId === childMenu?.permission
          )
        ) {
          doesUserHavePermission = true;
        }
      });
    }
  });

  return doesUserHavePermission;
};

export const isAuthenticated = (navigate, path = "/") => {
  const { user, token } = store.getState().auth;

  if (!user || !token) {
    logoutUser(navigate);
  } else {
    if (!isUserHavingTheRoutePermission(path)) {
      errorSweetAlert("You don't have the permissions to access that page");
      navigate(-1);
    }
  }
};

export const doesUserPermission = (per) => {
  const { user } = store.getState().auth;

  return user?.userRole?.rolePermissions?.find(
    (permission) => permission?.id === per
  );
};

export const getAllCountries = () =>
  Country.getAllCountries().map((country) => ({
    ...country,
    label: country.name,
    value: country.name,
  }));

export const getDisplayIconUrl = (doc) => {
  const fileExtension = doc?.split(".").pop().toLowerCase();
  switch (fileExtension) {
    case "doc":
    case "docx":
      // return "https://cdn.iconscout.com/icon/free/png-256/free-doc-file-1934509-1634559.png?f=webp";
      return "https://cdn-icons-png.freepik.com/512/8242/8242986.png";
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return "https://static-00.iconduck.com/assets.00/gallery-icon-2048x1997-3ifnwllu.png";
    case "pdf":
      return "https://continentaltextile.com.vn/wp-content/uploads/2023/12/pngtree-pdf-file-icon-png-png-image_7965915.png";
    case "xls":
    case "xlsx":
      return "https://cdn3d.iconscout.com/3d/premium/thumb/xls-file-7931962-6294886.png?f=webp";
    case "ppt":
    case "pptx":
      return "https://cdn3d.iconscout.com/3d/premium/thumb/ppt-file-5608814-4687429.png";
    case "mp4":
      return "https://static.vecteezy.com/system/resources/previews/027/292/609/original/mp4-file-format-of-3d-illustration-file-format-3d-concept-3d-rendering-png.png";
    case "mp3":
      return "https://cdn3d.iconscout.com/3d/premium/thumb/mp3-file-6927357-5651099.png";
    default:
      return "https://cdn-icons-png.flaticon.com/512/5064/5064889.png";
  }
};

export const getAddIconUrl = (doc) => {
  const fileExtension = doc?.name?.split(".").pop().toLowerCase();
  switch (fileExtension) {
    case "doc":
    case "docx":
      return "https://cdn.iconscout.com/icon/free/png-256/free-doc-file-1934509-1634559.png?f=webp";
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return "https://static-00.iconduck.com/assets.00/gallery-icon-2048x1997-3ifnwllu.png";
    case "pdf":
      return "https://continentaltextile.com.vn/wp-content/uploads/2023/12/pngtree-pdf-file-icon-png-png-image_7965915.png";
    case "xls":
    case "xlsx":
      return "https://cdn3d.iconscout.com/3d/premium/thumb/xls-file-7931962-6294886.png?f=webp";
    case "ppt":
    case "pptx":
      return "https://cdn3d.iconscout.com/3d/premium/thumb/ppt-file-5608814-4687429.png";
    case "mp4":
      return "https://static.vecteezy.com/system/resources/previews/027/292/609/original/mp4-file-format-of-3d-illustration-file-format-3d-concept-3d-rendering-png.png";
    case "mp3":
      return "https://cdn3d.iconscout.com/3d/premium/thumb/mp3-file-6927357-5651099.png";
    default:
      return "https://cdn-icons-png.flaticon.com/512/5064/5064889.png";
  }
};

export const individualStatus = (status) => {
  const statusColors = {
    bailed: "text-blue-400 border-blue-400",
    free: "text-green-500 border-green-500",
    arrested: "text-yellow-600 border-yellow-600",
  };

  const statusClass = statusColors[status];

  return (
    <>
      {" "}
      |{" "}
      <span
        className={`text-xs tracking-wide font-semibold capitalize border rounded pb-0.45 px-0.5 ${statusClass}`}
      >
        {status}
      </span>
    </>
  );
};
