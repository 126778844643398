import React from "react";
import CustomCard from "../../../../../components/generic/CustomCard";
import moment from "moment";

const MissionDetailsOverview = ({ missionDetails }) => {
  return (
    <div className="h-full w-full">
      <div className="grid grid-cols-4 gap-7 mt-10 mb-16">
        <CustomCard
          className={
            "bg-orange-50 w-full flex flex-col gap-3 items-center justify-center"
          }
        >
          <h1 className="text-3xl font-bold font-serif">Attachments</h1>
          <h2 className="text-2xl font-semibold font-mono">
            {missionDetails?.missionDocuments?.length || 0}
          </h2>
        </CustomCard>

        <div className="flex flex-col items-center justify-center">
          <h2 className="text-gray-500 mb-2 text-xl">CREATE DATE</h2>
          <h3 className="font-semibold">
            {moment(missionDetails?.createdAt).format("DD MMM, YYYY.")}
          </h3>
        </div>

        <CustomCard
          className={
            "w-full flex flex-col gap-3 items-center justify-center bg-teal-50"
          }
        >
          <h1 className="text-3xl font-bold font-serif">Comments</h1>
          <h2 className="text-2xl font-semibold font-mono">
            {missionDetails?.missionComments?.length || 0}
          </h2>
        </CustomCard>

        <div className="flex flex-col items-center justify-center">
          <h2 className="text-gray-500 mb-2 text-xl">LAST UPDATED AT</h2>
          <h3 className="font-semibold">
            {moment(missionDetails?.updatedAt).format("DD MMM, YYYY.")}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default MissionDetailsOverview;
