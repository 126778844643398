import React, { useState } from "react";
import {
  confirmationSweetAlert,
  errorToast,
  findFileKind,
  successToast,
  uuidV4,
} from "../../../../../helpers/helperFunctions";
import findFileIcon from "../../../../../helpers/findFileIcon";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { FaEllipsis, FaEye, FaTrash } from "react-icons/fa6";
import IsNoData from "../../../../../components/generic/IsNoData";
import moment from "moment";

import { useLocation } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";
import FileUploadModal from "./FileUploadModal";
import ViewFileCanvas from "./ViewFileCanvas";
import { useDeleteMissionAttachment } from "../../missionsQueries";

const MissionDetailsAttachments = ({ missionDetails }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [openViewFileCanvas, setOpenViewFileCanvas] = useState(false);
  const [openUploadFilesModal, setOpenUploadFilesModal] = useState(false);

  const queryClient = useQueryClient();

  const deleteFileMutation = useDeleteMissionAttachment();

  const handleDeleteFile = async (file) => {
    try {
      const { isConfirmed } = await confirmationSweetAlert();
      if (isConfirmed) {
        deleteFileMutation.mutateAsync(file?.id, {
          onSuccess: (_) => {
            queryClient.invalidateQueries(["getSingleMission"]);
            successToast("Attachment deleted successfully");
          },
          onError: (err) => {
            errorToast(
              err?.response?.data?.message ||
                "Server error: cannot delete attachment"
            );
          },
        });
      }
    } catch (err) {}
  };
  return (
    <div>
      <ViewFileCanvas
        key={`mission-details-attachment-${openViewFileCanvas}`}
        selectedFile={{
          ...selectedFile,
          name: selectedFile?.fileName,
        }}
        isOpen={openViewFileCanvas}
        missionDetails={missionDetails}
        setIsOpen={setOpenViewFileCanvas}
      />
      <FileUploadModal
        missionId={missionDetails?.id}
        isOpen={openUploadFilesModal}
        setIsOpen={setOpenUploadFilesModal}
      />

      <div className="flex items-center justify-end mb-2">
        <button
          onClick={() => setOpenUploadFilesModal(true)}
          className="p-1 px-3 rounded-md bg-orange-500 text-white text-sm"
        >
          Attach files
        </button>
      </div>
      {missionDetails?.missionDocuments?.length === 0 ? (
        <IsNoData message="No Attachments uploaded" />
      ) : (
        <table>
          <thead className="w-full whitespace-nowrap bg-gray-100">
            <th className="p-2 w-full whitespace-nowrap px-5 text-left">
              File Name
            </th>
            <th className="p-2 w-full whitespace-nowrap px-5 text-left">
              Type
            </th>

            <th className="p-2 w-full whitespace-nowrap px-5 text-left">
              Upload Date
            </th>
            <th className="p-2 w-full whitespace-nowrap px-5 text-left"></th>
          </thead>
          <tbody>
            {missionDetails?.missionDocuments?.map((file) => (
              <tr key={uuidV4()}>
                <td>
                  <div className="flex items-center gap-4 p-3 px-5">
                    {findFileIcon({ ...file, name: file?.fileName }, "w-7 h-7")}
                    <div className="flex-1">
                      <button
                        className="w-full text-left"
                        onClick={() => {
                          setSelectedFile(file);
                          setOpenViewFileCanvas(true);
                        }}
                      >
                        {file?.fileName}
                      </button>
                    </div>
                  </div>
                </td>

                <td className="p-2 whitespace-nowrap text-left px-5">
                  {findFileKind({ ...file, name: file?.fileName })}
                </td>

                <td className="p-2 whitespace-nowrap text-left px-5">
                  {moment(file?.createdAt).format("DD MMM, YYYY.")}
                </td>
                <td className="p-2 whitespace-nowrap text-left px-5">
                  <Menu
                    menuButton={
                      <button>
                        <FaEllipsis />
                      </button>
                    }
                  >
                    <MenuItem
                      onClick={() => {
                        setSelectedFile(file);
                        setOpenViewFileCanvas(true);
                      }}
                      className={"flex items-center gap-2 text-sm"}
                    >
                      <FaEye /> View
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setSelectedFile(file);
                        handleDeleteFile(file);
                      }}
                      className={"flex items-center gap-2 text-sm"}
                    >
                      <FaTrash /> Delete
                    </MenuItem>
                  </Menu>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MissionDetailsAttachments;
