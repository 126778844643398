import React, { createContext, useState } from "react";
import axiosConfig from "../http-common";
import { useCreateIncident } from "../pages/incident&case/incidentQueries";
import { errorToast, successToast } from "../helpers/helperFunctions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

const IncidentContext = createContext();

const IncidentContextProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [involvedIndividuals, setInvolvedIndividuals] = useState([]);
  const [offences, setOffences] = useState([]);

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    setCurrentStep(0);
    setInvolvedIndividuals([]);
  };

  const incidentMutation = useCreateIncident();

  const queryClient = useQueryClient();

  const onSubmit = async (values, { resetForm }) => {
    console.log("Valssss", values);

    console.log({
      ...values,
      incidentTypeIds: values.incidentTypeIds?.map(
        (incidentTypeId) => incidentTypeId?.value
      ),
    });
    try {
      await incidentMutation.mutateAsync(
        {
          ...values,
          incidentTypeIds: values.incidentTypeIds?.map(
            (incidentTypeId) => incidentTypeId?.value
          ),
        },
        {
          onError: (err) => {
            errorToast(
              err?.response?.data?.message || "Error creating incident"
            );
          },
          onSuccess: (data) => {
            successToast(data.message);
            onCloseModal();
            resetForm();
            queryClient.invalidateQueries(["getIncidents"]);
          },
        }
      );
    } catch (error) {
      errorToast(error?.response?.data?.message || "Error creating incident");
      console.error("Error submitting form:", error);
    }
  };

  const { user } = useSelector((state) => state.auth);

  const {
    values,
    setValues,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
    setTouched,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
      caseDescription: "",
      incidentDate: "",
      incidentTypeIds: [],
      region: user?.userLocation?.state
        ? user?.userLocation?.state
        : "Not available",
      city: user?.userLocation?.locationName
        ? user?.userLocation?.locationName
        : "Not available",
      cityId: "",
      station: user?.userLocation?.address
        ? user?.userLocation?.address
        : "Not available",
      reportedBy: user?.id,
      reportedAt: Date.now(),
      numbOfInvolved: 1,
      involvedIndividuals: [],
      offences: [],
      station: user?.locationId,
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      caseDescription: Yup.string().required("Case description is required"),
      incidentDate: Yup.date().required("Incident date is required"),
      incidentTypeIds: Yup.array().min(1, "Atleast one offence is required"),

      cityId: Yup.string().required("City is required"),
      regionId: Yup.string().required("Region is required"),
      station: Yup.string().required("Station is required"),
      reportedBy: Yup.string().required("Reported by is required"),

      involvedIndividuals: Yup.array().of(
        Yup.object().shape({
          involvement: Yup.string().required("Involvement is required"),
          firstName: Yup.string().required("First name is required"),
          lastName: Yup.string().required("Last name is required"),
          gender: Yup.string().required("Gender is required"),
          // age: Yup.number().required("Age is required"),
          phoneNumber: Yup.string().required("Phone number is required"),
          address: Yup.string().required("Address is required"),
          nationality: Yup.string().required("Nationality is required"),
          idType: Yup.string().required("ID type is required"),
          idNumber: Yup.string().required("ID number is required"),
          statement: Yup.string().required("Statement is required"),
          occupation: Yup.string().required("Occupation is required"),
          additionalInfo: Yup.string().required("Additional is required"),
          dob: Yup.string().required("Date of birth is required"),
        })
      )
    }),
  });

  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  const submitForm = async (e, values) => {
    e.preventDefault();

    try {
      await incidentMutation.mutateAsync(formData, {
        onError: (err) => {
          errorToast(err?.response?.data?.message || "Error creating incident");
        },
        onSuccess: (data) => {
          successToast(data.message);
          queryClient.invalidateQueries(["getIncidents"]);
        },
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const contextValue = {
    formData,
    setFormData,
    currentStep,
    nextStep,
    prevStep,
    submitForm,
    values,
    handleChange,
    setValues,
    involvedIndividuals,
    setInvolvedIndividuals,
    onSubmit,
    handleSubmit,
    open,
    setOpen,
    onCloseModal,
    onOpenModal,
    errors,
    offences,
    setOffences,
    setTouched,
  };

  return (
    <IncidentContext.Provider value={contextValue}>
      {children}
    </IncidentContext.Provider>
  );
};

export { IncidentContext, IncidentContextProvider };
