import { useQuery } from "@tanstack/react-query";
import axiosConfig from "../http-common";

export const useGetStorage = () =>
    useQuery({
      retry: 1,
      queryKey: ["getStorage"],
      queryFn: async () =>
        await axiosConfig.get("/diskUsage"),
      refetchInterval: 10000,
    });