import React from "react";
import "./CustomSpinner.css";
const CustomSpinner = () => {
  return (
    <div className="lds-ripple">
      <div></div>
      <div></div>
    </div>
  );
};

export default CustomSpinner;
