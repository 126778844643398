import classNames from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

const CustomCard = ({ children, className }) => {
  return (
    <div
      className={classNames(
        twMerge(
          "bg-white rounded-lg shadow-lg p-5 w-80 overflow-hidden",
          className
        )
      )}
    >
      {children}
    </div>
  );
};

export default CustomCard;
