import { lazy } from "react";
import MissionDetailsPage from "./pages/operations/operations&missions/details";

const IncidentType = lazy(() =>
  import("./pages/system-management/incident-type-management")
);
const Wanted = lazy(() => import("./pages/operations/wanted"));
const EditIncidentReportPage = lazy(() =>
  import("./pages/operations/reports/incident-report/edit")
);
const Statute = lazy(() => import("./pages/system-management/statute"));
const OperationsMissions = lazy(() =>
  import("./pages/operations/operations&missions")
);
const RecordDetailsPage = lazy(() =>
  import("./pages/records-management/record-details")
);
const AddUserPage = lazy(() =>
  import("./pages/system-management/user-management/add-user")
);
const EditUserPage = lazy(() =>
  import("./pages/system-management/user-management/edit-user")
);
const UserDetailsPage = lazy(() =>
  import("./pages/system-management/user-management/user-details")
);
const RecordsManagementPage = lazy(() => import("./pages/records-management"));
const LocationManagementPage = lazy(() =>
  import("./pages/system-management/location-management")
);
const LocationDetailsPage = lazy(() =>
  import("./pages/system-management/location-management/location-details")
);
const AddLocationPage = lazy(() =>
  import("./pages/system-management/location-management/add-location")
);
const EditLocationPage = lazy(() =>
  import("./pages/system-management/location-management/edit-location")
);
const DepartmentsPage = lazy(() =>
  import("./pages/system-management/departments")
);
const ManageTaskPage = lazy(() =>
  import("./pages/task-management/manage-task")
);
const TaskBoardPage = lazy(() => import("./pages/task-management/task-board"));
const DocumentTypes = lazy(() =>
  import("./pages/system-management/record-types")
);
const MemosPage = lazy(() => import("./pages/memos"));
const MemoDetailsPage = lazy(() => import("./pages/memos/details"));

const SettingsPage = lazy(() => import("./pages/settings"));
const ActionsPage = lazy(() => import("./pages/system-management/action"));

const AddSettingsPage = lazy(() => import("./pages/settings/add-settings"));
const EditSettingsPage = lazy(() => import("./pages/settings/edit-settings"));
const TaskListPage = lazy(() => import("./pages/task-management/task-list"));
const ViewTaskPage = lazy(() => import("./pages/task-management/view-task"));
const RanksPage = lazy(() => import("./pages/system-management/ranks"));

const ResetPasswordPage = lazy(() => import("./pages/auth/reset-password"));
const NotFoundPage = lazy(() => import("./pages/not-found"));
const DashboardPage = lazy(() => import("./pages/dashboard"));
const LockScreenPage = lazy(() => import("./pages/auth/lock-screen"));
const LoginPage = lazy(() => import("./pages/auth/login"));
const LogsPage = lazy(() => import("./pages/system-management/logs"));
const ProfilePage = lazy(() => import("./pages/auth/profile"));
const RolesAndPermissionsPage = lazy(() =>
  import("./pages/system-management/roles-and-permissions")
);
const UsersPage = lazy(() =>
  import("./pages/system-management/user-management")
);

const AllRecordsPage = lazy(() =>
  import("./pages/records-management/all-records")
);
const UnAssignedRecordsPage = lazy(() =>
  import("./pages/records-management/unassigned-records")
);
const CompletedRecordsPage = lazy(() =>
  import("./pages/records-management/completed-records")
);
const ReadyForDispatchPage = lazy(() =>
  import("./pages/records-management/ready-for-dispatch")
);

const ArchievedRecordsPage = lazy(() =>
  import("./pages/records-management/archieved-records")
);

const ClassifiedRecordsPage = lazy(() =>
  import("./pages/records-management/classified-records")
);
const IncidentCase = lazy(() => import("./pages/incident&case/IncidentCase"));
const ArrestBookings = lazy(() =>
  import("./pages/incident&case/ArrestBookings")
);
const BailSortee = lazy(() => import("./pages/incident&case/BailSortee"));
const StationDairy = lazy(() => import("./pages/incident&case/StationDiary"));
const ManageOffender = lazy(() =>
  import("./pages/incident&case/ManageOffender")
);
const ManageEvidence = lazy(() =>
  import("./pages/incident&case/ManageEvidence")
);
const WarrantManagement = lazy(() =>
  import("./pages/incident&case/WarrantManagement")
);

const ChatPage = lazy(() => import("./pages/chat/index"));
const ViewIncidentReport = lazy(() =>
  import("./pages/incident&case/ViewIncidentReport/index")
);

const IncidentReportPage = lazy(() =>
  import("./pages/operations/reports/incident-report")
);

const ViewIncidentReportPage = lazy(() =>
  import("./pages/operations/reports/incident-report/view-report")
);

const CreateIncidentReportPage = lazy(() =>
  import("./pages/operations/reports/incident-report/create")
);

const OpsReportPage = lazy(() =>
  import("./pages/operations/reports/ops-report")
);

const ArrestReportPage = lazy(() =>
  import("./pages/operations/reports/arrest-report")
);

// const ViewArrestReportPage = lazy(() =>
//   import("./pages/operations/reports/arrest-report/view-report")
// );

const InvestigationsPage = lazy(() =>
  import("./pages/operations/investigations")
);

const PoliceStationsPage = lazy(() =>
  import("./pages/operations/police-stations")
);

const IncidentActivityReportPage = lazy(() =>
  import("./pages/operations/reports/incident-activity-report")
);

const GroupManagementPage = lazy(() =>
  import("./pages/system-management/group-management")
);

const PriorityLevelsPage = lazy(() =>
  import("./pages/system-management/priority-levels")
);

const SearchPage = lazy(() =>
  import("./pages/dashboard/components/SearchPage")
);

const FAQ = lazy(() => import("./pages/faq/index"));

const routes = [
  {
    title: "Login",
    path: "/",
    isProtected: false,
    element: <LoginPage />,
  },
  {
    title: "Lock Screen",
    path: "/lock-screen",
    isProtected: false,
    element: <LockScreenPage />,
  },
  {
    title: "Reset Password",
    path: "/reset-password",
    isProtected: false,
    element: <ResetPasswordPage />,
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    parentPath: "/dashboard",
    isProtected: true,
    element: <DashboardPage />,
  },
  {
    title: "Profile",
    path: "/profile",
    parentPath: "/profile",
    isProtected: true,
    element: <ProfilePage />,
  },

  // System Management
  {
    title: "Users",
    path: "/users",
    parentPath: "/users",
    isProtected: true,
    element: <UsersPage />,
  },
  {
    title: "User Details",
    path: "/users/details",
    parentPath: "/users",
    isProtected: true,
    element: <UserDetailsPage />,
  },
  {
    title: "Add User",
    path: "/users/add",
    parentPath: "/users",
    isProtected: true,
    element: <AddUserPage />,
  },
  {
    title: "Edit User",
    path: "/users/edit",
    parentPath: "/users",
    isProtected: true,
    element: <EditUserPage />,
  },
  {
    title: "Group Management",
    path: "/group-management",
    parentPath: "/group-management",
    isProtected: true,
    element: <GroupManagementPage />,
  },
  {
    title: "Roles and Permissions",
    path: "/roles-and-permissions",
    parentPath: "/roles-and-permissions",
    isProtected: true,
    element: <RolesAndPermissionsPage />,
  },

  {
    title: "Location Management",
    path: "/location-management",
    parentPath: "/location-management",
    isProtected: true,
    element: <LocationManagementPage />,
  },

  {
    title: "Location details",
    path: "/location-management/details",
    parentPath: "/location-management",
    isProtected: true,
    element: <LocationDetailsPage />,
  },
  {
    title: "Add new location",
    path: "/location-management/add",
    parentPath: "/location-management",
    isProtected: true,
    element: <AddLocationPage />,
  },
  {
    title: "Edit location",
    path: "/location-management/edit",
    parentPath: "/location-management",
    isProtected: true,
    element: <EditLocationPage />,
  },
  {
    title: "Logs",
    path: "/logs",
    parentPath: "/logs",
    isProtected: true,
    element: <LogsPage />,
  },

  {
    title: "Department Management",
    path: "/department-management",
    parentPath: "/department-management",
    isProtected: true,
    element: <DepartmentsPage />,
  },

  {
    title: "Record Types",
    path: "/record-types",
    parentPath: "/record-types",
    isProtected: true,
    element: <DocumentTypes />,
  },

  {
    title: "Ranks & Regiment Mngt",
    path: "/ranks",
    parentPath: "/ranks",
    isProtected: true,
    element: <RanksPage />,
  },
  // records management
  {
    title: "Records Management",
    path: "/records-management",
    parentPath: "/records-management",
    isProtected: true,
    element: <RecordsManagementPage />,
  },
  {
    title: "Record Details",
    path: "/records-management/record-details",
    parentPath: "/records-management/record-details",
    isProtected: true,
    element: <RecordDetailsPage />,
  },

  {
    title: "Unassigned Records",
    path: "/unassigned-records",
    parentPath: "/unassigned-records",
    isProtected: true,
    element: <UnAssignedRecordsPage />,
  },
  {
    title: "All Records",
    path: "/all-records",
    parentPath: "/all-records",
    isProtected: true,
    element: <AllRecordsPage />,
  },
  {
    title: "Completed Records",
    path: "/completed-records",
    parentPath: "/completed-records",
    isProtected: true,
    element: <CompletedRecordsPage />,
  },
  {
    title: "Ready for Dispatch",
    path: "/ready-for-dispatch",
    parentPath: "/ready-for-dispatch",
    isProtected: true,
    element: <ReadyForDispatchPage />,
  },

  {
    title: "Archieved Record",
    path: "/archieved-records",
    parentPath: "/archieved-records",
    isProtected: true,
    element: <ArchievedRecordsPage />,
  },
  {
    title: "Classified Record",
    path: "/classified-records",
    parentPath: "/classified-records",
    isProtected: true,
    element: <ClassifiedRecordsPage />,
  },

  // task management
  {
    title: "Manage Task",
    path: "/task-management",
    parentPath: "/task-management",
    isProtected: true,
    element: <ManageTaskPage />,
  },
  {
    title: "Task Board",
    path: "/task-management/task-board",
    parentPath: "/task-management/task-board",
    isProtected: true,
    element: <TaskBoardPage />,
  },

  {
    title: "Task List",
    path: "/task-management/task-list",
    parentPath: "/task-management/task-list",
    isProtected: true,
    element: <TaskListPage />,
  },
  {
    title: "Task Details",
    path: "/task-management/view-task",
    parentPath: "/task-management/view-task",
    isProtected: true,
    element: <ViewTaskPage />,
  },
  {
    title: "404 Page Not Found",
    path: "*",
    isProtected: false,
    element: <NotFoundPage />,
  },

  {
    title: "Memos",
    path: "/memos",
    parentPath: "/memos",
    isProtected: true,
    element: <MemosPage />,
  },
  {
    title: "Memo Details",
    path: "/memos/details",
    parentPath: "/memos/details",
    isProtected: true,
    element: <MemoDetailsPage />,
  },

  // routing roules
  {
    title: "Routing Rules",
    path: "/settings",
    parentPath: "/settings",
    isProtected: true,
    element: <SettingsPage />,
  },
  {
    title: "Add Routing Rules",
    path: "/settings/add",
    parentPath: "/settings",
    isProtected: true,
    element: <AddSettingsPage />,
  },
  {
    title: "Edit Routing Rules",
    path: "/settings/edit",
    parentPath: "/settings",
    isProtected: true,
    element: <EditSettingsPage />,
  },
  {
    title: "Edit Routing Rules",
    path: "/settings/edit",
    parentPath: "/settings",
    isProtected: true,
    element: <EditSettingsPage />,
  },
  {
    title: "Actions",
    path: "/actions",
    parentPath: "/actions",
    isProtected: true,
    element: <ActionsPage />,
  },
  {
    title: "Priority Levels",
    path: "/priority-levels",
    parentPath: "/priority-levels",
    isProtected: true,
    element: <PriorityLevelsPage />,
  },

  {
    title: "Statute",
    path: "/statute",
    parentPath: "/statute",
    isProtected: true,
    element: <Statute />,
  },

  {
    title: "Incident Management",
    path: "/incident-management",
    parentPath: "/incident-management",
    isProtected: true,
    element: <IncidentCase />,
  },
  {
    title: "Arrest & Bookings",
    path: "/arrest-bookings",
    parentPath: "/arrest-bookings",
    isProtected: true,
    element: <ArrestBookings />,
  },
  {
    title: "Bail & Sortee",
    path: "/bail-sortee",
    parentPath: "/bail-sortee",
    isProtected: true,
    element: <BailSortee />,
  },
  {
    title: "Station Diary",
    path: "/station-diary",
    parentPath: "/station-diary",
    isProtected: true,
    element: <StationDairy />,
  },
  {
    title: "Manage Offender",
    path: "/manage-offender",
    parentPath: "/manage-offender",
    isProtected: true,
    element: <ManageOffender />,
  },
  {
    title: "Manage Evidence",
    path: "/manage-evidence",
    parentPath: "/manage-evidence",
    isProtected: true,
    element: <ManageEvidence />,
  },
  {
    title: "Warrant Management",
    path: "/warrant-management",
    parentPath: "/warrant-management",
    isProtected: true,
    element: <WarrantManagement />,
  },

  // Operations

  {
    title: "Investigations and Intellegence",
    path: "/operations/investigations",
    parentPath: "/operations/investigations",
    isProtected: true,
    element: <InvestigationsPage />,
  },

  {
    title: "Police Stations",
    path: "/operations/police-stations",
    parentPath: "/operations/police-stations",
    isProtected: true,
    element: <PoliceStationsPage />,
  },

  // Ops Report
  {
    title: "Ops Report",
    path: "/operations/reports/ops-report",
    parentPath: "/operations/reports/ops-report",
    isProtected: true,
    element: <OpsReportPage />,
  },

  // Incident
  {
    title: "Incident Report",
    path: "/operations/reports/incident-report",
    parentPath: "/operations/reports/incident-report",
    isProtected: true,
    element: <IncidentReportPage />,
  },
  {
    title: "Incident Report Details",
    path: "/operations/reports/incident-report/view",
    parentPath: "/operations/reports/incident-report/view",
    isProtected: true,
    element: <ViewIncidentReportPage />,
  },
  {
    title: "Create Incident Report",
    path: "/operations/reports/incident-report/create",
    parentPath: "/operations/reports/incident-report/create",
    isProtected: true,
    element: <CreateIncidentReportPage />,
  },
  {
    title: "Edit Incident Report",
    path: "/operations/reports/incident-report/edit",
    parentPath: "/operations/reports/incident-report/edit",
    isProtected: true,
    element: <EditIncidentReportPage />,
  },
  // Incident Activity Report
  {
    title: "Incident Activity Report",
    path: "/operations/reports/incident-activity-report",
    parentPath: "/operations/reports/incident-activity-report",
    isProtected: true,
    element: <IncidentActivityReportPage />,
  },

  // Arrest
  {
    title: "Arrest Report",
    path: "/operations/reports/arrest-report",
    parentPath: "/operations/reports/arrest-report",
    isProtected: true,
    element: <ArrestReportPage />,
  },
  // {
  //   title: "Arrest Report",
  //   path: "/operations/reports/arrest-report/view",
  //   parentPath: "/operations/reports/arrest-report/view",
  //   isProtected: true,
  //   element: <ViewArrestReportPage />,
  // },

  {
    title: "Chat",
    path: "/chat",
    parentPath: "/chat",
    isProtected: true,
    element: <ChatPage />,
  },
  {
    title: "View Incident",
    path: "/view-incident/:id",
    parentPath: "/incident-management",
    isProtected: true,
    element: <ViewIncidentReport />,
  },
  {
    title: "Incident Type Management",
    path: "/incident-type",
    parentPath: "/incident-type",
    isProtected: true,
    element: <IncidentType />,
  },
  {
    title: "Wanted / POI",
    path: "/wanted",
    parentPath: "/wanted",
    isProtected: true,
    element: <Wanted />,
  },
  {
    title: "Operations / Missions",
    path: "/operations/missions",
    parentPath: "/operations/missions",
    isProtected: true,
    element: <OperationsMissions />,
  },
  {
    title: "Mission Details",
    path: "/operations/missions/details",
    parentPath: "/operations/missions/details",
    isProtected: true,
    element: <MissionDetailsPage />,
  },
  {
    title: "Search Page",
    path: "/search-results",
    parentPath: "/search-results",
    isProtected: true,
    element: <SearchPage />,
  },
  {
    title: "Help & FAQ",
    path: "/faq",
    parentPath: "/faq",
    isProtected: true,
    element: <FAQ />,
  },
];

export default routes;
