import React from "react";

const IsNoData = ({
  message = "No data found. Please click on the add button to add more",
}) => {
  return (
    <div className="p-3 py-7 flex flex-col justify-center items-center gap-3">
      <img
        src="https://static.vecteezy.com/system/resources/previews/009/007/126/non_2x/document-file-not-found-search-no-result-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg"
        alt=""
        className="w-[150px] h-[150px] object-scale-down"
      />
      <p className="text-main font-semibold">{message}</p>
    </div>
  );
};

export default IsNoData;
