import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import { useWindowSize } from "react-use";
import CustomCloseButton from "../../../../../components/generic/CustomCloseButton";
import { useReadFile } from "../../missionsQueries";
import CustomCard from "../../../../../components/generic/CustomCard";
import ErrorIcon from "../../../../../assets/icons/error.png";
import DownloadIcon from "../../../../../assets/icons/download.png";
import CustomSpinner from "../../../../../components/generic/CustomSpinner";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { errorToast } from "../../../../../helpers/helperFunctions";

const ViewFileCanvas = ({
  isOpen,
  setIsOpen,
  selectedFile,
  missionDetails,
}) => {
  const { height } = useWindowSize();
  const [docs, setDocs] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [blobData, setBlobData] = useState();
  const readFileMutation = useReadFile();
  const handleReadFile = async () => {
    try {
      await readFileMutation.mutateAsync(
        {
          fileId: selectedFile?.id,
          missionId: missionDetails?.id,
        },
        {
          onError: (err) => {
            console.log(err);
          },
          onSuccess: (data) => {
            setBlobData(data);
            if (selectedFile?.name?.toLowerCase()?.includes(".pdf")) {
              setDocs([{ uri: data, fileName: selectedFile.name }]);
            } else {
              setIsDownload(true);
            }
          },
        }
      );
    } catch (err) {}
  };

  useEffect(() => {
    if (selectedFile) handleReadFile();
  }, [selectedFile]);

  const handleDownload = () => {
    try {
      const link = document.createElement("a");
      link.href = blobData;
      link.download = selectedFile?.name || "";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (err) {
      console.log(err);
      errorToast("Server error: cannot download file");
    }
  };

  const ErrorComponent = () => (
    <div className="w-full h-full flex items-center justify-center">
      <CustomCard className={"flex flex-col justify-center items-center gap-4"}>
        <div>
          <img src={ErrorIcon} alt="" className="w-28" />
        </div>

        <div className="flex-1 flex flex-col gap-2">
          <p className="text-sm">
            The file you are trying is accessing is either not available in our
            system or your internet is down.
          </p>
          <p className="text-sm">
            Please check your internet connection and try again.
          </p>
        </div>
      </CustomCard>
    </div>
  );

  const LoadingComponent = () => (
    <div className="w-full h-full flex items-center justify-center">
      <div>
        <CustomSpinner />
      </div>
    </div>
  );

  const DownloadComponent = () => (
    <div className="w-full h-full flex items-center justify-center">
      <CustomCard
        className={"flex flex-col justify-center items-center gap-4 shadow-2xl"}
      >
        <div>
          <img src={DownloadIcon} alt="" className="w-20" />
        </div>

        <div className="flex-1 flex flex-col gap-2 mt-4">
          <p className="text-sm">Our system cannot read this file.</p>
          <p className="text-sm">
            Please click on download to download the file instead.
          </p>

          <button
            onClick={handleDownload}
            className="flex gap-2 items-center p-2 border border-gray-500 w-fit px-5 mt-5 mx-auto bg-transparent hover:bg-main/5"
          >
            <img src={DownloadIcon} alt="" className="w-5 object-scale-down" />
            Download File
          </button>
        </div>
      </CustomCard>
    </div>
  );

  return (
    <Drawer
      size={height}
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      direction="top"
      className="rounded-md p-5"
    >
      <div className="h-full w-full flex flex-col gap-3">
        {/* Hedare */}
        <div className="flex items-center justify-between mb-5">
          <div></div>
          <CustomCloseButton setIsOpen={setIsOpen} />
        </div>

        {/*  Body*/}
        <div className="flex-1 h-full">
          {readFileMutation?.isPending ? (
            <LoadingComponent />
          ) : readFileMutation.isError ? (
            <ErrorComponent />
          ) : isDownload ? (
            <DownloadComponent />
          ) : (
            <DocViewer
              style={{ width: "100%" }}
              className="h-full"
              documents={docs}
              pluginRenderers={DocViewerRenderers}
              config={{
                pdfVerticalScrollByDefault: true,
              }}
            />
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default ViewFileCanvas;
