import { useMutation, useQuery } from "@tanstack/react-query";
import axiosConfig from "../../http-common";

export const useCreateIncident = () =>
  useMutation({
    mutationKey: ["createIncident"],
    mutationFn: async (formData) =>
      await axiosConfig.post("/incidents", formData),
  });

export const useGetIncidents = (filters) =>
  useQuery({
    retry: 1,
    queryKey: [
      "getIncidents",
      {
        ...filters,
      },
    ],
    queryFn: async () =>
      await axiosConfig.get("/incidents/incidentNew/getAll", {
        params: filters,
      }),
    refetchInterval: 10000,
  });

export const useGetIncidentArrestations = (id, filters) =>
  useQuery({
    retry: 1,
    queryKey: ["getIncidentArrestations", id],
    queryFn: async () =>
      await axiosConfig.get(`/incidents/incidentNew/getArrestationInfo/${id}`, {
        params: filters,
      }),
    refetchInterval: 10000,
  });

export const useGetIncidentCharges = (id, filters) =>
  useQuery({
    retry: 1,
    queryKey: ["getIncidentCharges", id],
    queryFn: async () =>
      await axiosConfig.get(`/incidents/incidentNew/getChargesInfo/${id}`, {
        params: filters,
      }),
    refetchInterval: 10000,
  });

export const useGetIncidentComments = (id, filters) =>
  useQuery({
    retry: 1,
    queryKey: ["getIncidentComments", id],
    queryFn: async () =>
      await axiosConfig.get(`/incidentComment/getIncidentComments/${id}`, {
        params: filters,
      }),
    refetchInterval: 10000,
  });

export const useGetSingleIncident = (id) =>
  useQuery({
    retry: 1,
    queryKey: ["getSingleIncident", id],
    queryFn: async () =>
      await axiosConfig.get(`/incidents/incidentNew/getSingle/${id}`),
  });

export const useGetVictimInfo = (id) =>
  useQuery({
    retry: 1,
    queryKey: ["getVictimInfo", id],
    queryFn: async () =>
      await axiosConfig.get(`/incidents/incidentNew/getVictimsInfo/${id}`),
  });

export const useGetReporterInfo = (id) =>
  useQuery({
    retry: 1,
    queryKey: ["getReporterInfo", id],
    queryFn: async () =>
      await axiosConfig.get(`/incidents/incidentNew/getReportersInfo/${id}`),
  });

export const useGetWitnessInfo = (id) =>
  useQuery({
    retry: 1,
    queryKey: ["getWitnessInfo", id],
    queryFn: async () =>
      await axiosConfig.get(`/incidents/incidentNew/getWitnessInfo/${id}`),
  });

export const useGetSuspectInfo = (id) =>
  useQuery({
    retry: 1,
    queryKey: ["getSuspectInfo", id],
    queryFn: async () =>
      await axiosConfig.get(`/incidents/incidentNew/getSuspectsInfo/${id}`),
  });

export const useGetEvidences = (id) =>
  useQuery({
    retry: 1,
    queryKey: ["getEvidences", id],
    queryFn: async () => await axiosConfig.get(`/evidence/incident/${id}`),
  });

export const useAddInvolvedIndividual = (id) =>
  useMutation({
    mutationKey: ["addInvolvedIndividual", id],
    mutationFn: async (formData) =>
      await axiosConfig.post(`/incidents/involved-individuals`, formData),
  });

export const useMakeIndividualWanted = (id) =>
  useMutation({
    mutationKey: ["makeIndividualWanted", id],
    mutationFn: async (formData) =>
      await axiosConfig.put(`/incidents/involved-individuals/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      }),
  });

export const useUpdateInvolvedIndividual = (id) =>
  useMutation({
    mutationKey: ["updateInvolvedIndividual", id],
    mutationFn: async (formData) =>
      await axiosConfig.put(`/incidents/involved-individuals/${id}`, formData),
  });

export const useUploadInvolvedIndividualProfile = (id) =>
  useMutation({
    mutationKey: ["uploadInvolvedIndividualProfile", id],
    mutationFn: async (formData) =>
      await axiosConfig.put(
        `/involvedIndividual/profileImage/${id}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      ),
  });

export const useToggleWanted = (id) =>
  useMutation({
    mutationKey: ["toggleWanted", id],
    mutationFn: async () =>
      await axiosConfig.put(`/involvedIndividual/${id}/wanted`),
  });

export const useGetLocationDiaries = (id, filters) =>
  useQuery({
    retry: 1,
    queryKey: [
      "getLocationDiaries",
      {
        id,
        ...filters,
      },
    ],
    queryFn: async () =>
      await axiosConfig.get(`/stationDiary/location/${id}`, {
        params: filters,
      }),
  });

export const useGetStationDiaries = (filters) =>
  useQuery({
    retry: 1,
    queryKey: ["getStationDiaries"],
    queryFn: async () =>
      await axiosConfig.get(`/stationDiary/`, {
        params: filters,
      }),
  });

export const useCreateDiaryItem = () =>
  useMutation({
    mutationKey: ["createDiaryItem"],
    mutationFn: async (formData) =>
      await axiosConfig.post("/stationDiary/", formData),
  });

export const useEditDiaryItem = (id) =>
  useMutation({
    mutationKey: ["editDiaryItem", id],
    mutationFn: async (formData) =>
      await axiosConfig.put(`/stationDiary/${id}`, formData),
  });

export const useGetArrests = (filters) =>
  useQuery({
    queryKey: [
      "getArrests",
      {
        ...filters,
      },
    ],
    queryFn: async () =>
      await axiosConfig.get("/arrest", {
        params: filters,
      }),
  });

export const useCreateArrest = () =>
  useMutation({
    mutationKey: ["createArrest"],
    mutationFn: async (formData) =>
      await axiosConfig.post("/incidents/arrest-suspect", formData),
  });

export const useUpdateIncident = (inciId) =>
  useMutation({
    mutationKey: ["updateIncident"],
    mutationFn: async (formData) =>
      await axiosConfig.put(`/incidents/${inciId}`, formData),
  });

export const useCreateEvidence = () =>
  useMutation({
    mutationKey: ["createEvidence"],
    mutationFn: async (formData) =>
      await axiosConfig.post("/evidence/uploadEvidence", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
  });

export const useReadEvidenceFile = (id) =>
  useMutation({
    mutationKey: ["readEvidenceFile"],
    mutationFn: async (formData) =>
      await axiosConfig.post(`/evidence/readEvidenceFile/${id}`, formData, {
        responseType: "blob",
      }),
  });

export const useAddBail = () =>
  useMutation({
    mutationKey: ["addBail"],
    mutationFn: async (formData) => await axiosConfig.post("/bail", formData),
  });

export const useGetBail = (filters) =>
  useQuery({
    retry: 1,
    queryKey: [
      "getBail",
      {
        ...filters,
      },
    ],
    queryFn: async () =>
      await axiosConfig.get("/bail/user/location-bail", {
        params: filters,
      }),
  });

export const useGetCharge = () =>
  useQuery({
    retry: 1,
    queryKey: ["getCharge"],
    queryFn: async () => await axiosConfig.get("/charges"),
  });

export const useCreateCharge = () =>
  useMutation({
    mutationKey: ["createCharge"],
    mutationFn: async (formData) =>
      await axiosConfig.post("/charges", formData),
  });

export const useEditCharge = (id) =>
  useMutation({
    mutationKey: ["editCharge", id],
    mutationFn: async (formData) =>
      await axiosConfig.put(`/charges/${id}`, formData),
  });

export const useGetCities = () =>
  useQuery({
    retry: 1,
    queryKey: ["getCities"],
    queryFn: async () => await axiosConfig.get("/cities"),
  });

export const useGetRegions = () =>
  useQuery({
    retry: 1,
    queryKey: ["getRegions"],
    queryFn: async () => await axiosConfig.get("/regions"),
  });

export const useSearchIncident = (incidentNumber) =>
  useQuery({
    retry: 1,
    queryKey: ["searchIncident", incidentNumber],
    queryFn: async () =>
      await axiosConfig.get(`/incidents`, { params: { incidentNumber } }),
  });

export const useGetEvidence = (filters) =>
  useQuery({
    queryKey: ["getEvidence", filters],
    queryFn: async () =>
      await axiosConfig.get("/evidence", {
        params: filters,
      }),
  });

export const useBuildCase = () =>
  useMutation({
    mutationKey: ["buildCase"],
    mutationFn: async (formData) =>
      await axiosConfig.post("/cases/build-case", formData),
  });

export const useAddComment = () =>
  useMutation({
    mutationKey: ["addComment"],
    mutationFn: async (formData) =>
      await axiosConfig.post("/incidentComment", formData),
  });

export const useGetComments = () =>
  useQuery({
    retry: 1,
    queryKey: ["getComments"],
    queryFn: async () => await axiosConfig.get("/comment"),
  });

export const useEditComment = (id) =>
  useMutation({
    mutationKey: ["editComment"],
    mutationFn: async (formData) =>
      await axiosConfig.put(`/incidentComment/${id}`, formData),
  });

export const useGetSingleComment = (id) =>
  useQuery({
    retry: 1,
    queryKey: ["getSingleComment"],
    queryFn: async () => await axiosConfig.get(`/comment/${id}`),
  });

export const useGetOffenders = () =>
  useQuery({
    retry: 1,
    queryKey: ["getOffenders"],
    queryFn: async () => await axiosConfig.get("/offenders"),
  });

export const useGetAllDepartments = () =>
  useQuery({
    retry: 1,
    queryKey: ["getAllDepartments"],
    queryFn: async () => await axiosConfig.get("/department"),
  });

export const useGetAllStatutes = () =>
  useQuery({
    queryKey: ["getAllStatutes"],
    queryFn: async () => await axiosConfig.get("/statute"),
  });

export const useAddStatement = () =>
  useMutation({
    mutationKey: ["addStatement"],
    mutationFn: async (formData) =>
      await axiosConfig.post("/statements", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
  });

export const useEditStatement = (id) =>
  useMutation({
    mutationKey: ["editStatement", id],
    mutationFn: async (formData) =>
      await axiosConfig.put(`/statements/${id}`, formData),
  });

export const useGetStatements = () =>
  useQuery({
    queryKey: ["getStatements"],
    queryFn: async () => await axiosConfig.get("/statements"),
  });

export const useCloseCase = (id) =>
  useMutation({
    mutationKey: ["closeCase", id],
    mutationFn: async (formData) =>
      await axiosConfig.put(`/cases/status/${id}`, formData),
  });
