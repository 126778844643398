import {
  FaBook,
  FaBookSkull,
  FaBuildingUser,
  FaCertificate,
  FaChalkboardUser,
  FaClipboardCheck,
  FaComputer,
  FaFolder,
  FaGauge,
  FaGears,
  FaMemory,
  FaMessage,
  FaMinus,
  FaPeopleArrows,
  FaPeopleCarryBox,
  FaPerson,
  FaRegAddressBook,
  FaStore,
  FaTableCells,
  FaTruckFast,
  FaRegNewspaper,
} from "react-icons/fa6";
import { FaQuestionCircle } from "react-icons/fa";

const menus = [
  {
    title: "Dashboard",
    permission: "6e5f6992-e1a7-429f-b7d5-423e0f6b976d",
    link: "/dashboard",
    icon: <FaGauge className="text-xl" />,
  },

  {
    title: "Records Management",
    icon: <FaFolder className="text-xl" />,
    permission: "aaca8864-4c4c-4ce8-8892-6238af25db44",
    link: "#!",

    children: [
      {
        title: "New / Unassigned",
        permission: "598e2a52-eca5-44fd-a898-ac798d88f90b",
        link: "/unassigned-records",
      },
      {
        title: "Records explorer",
        permission: "4a053aa6-faa3-4a87-bf68-95b6c25a6e90",
        link: "/records-management",
      },
      {
        title: "All records",
        permission: "94e441cd-7a17-4465-8b87-e92b8eca4eda",
        link: "/all-records",
      },
      {
        title: "Classified Records",
        permission: "bdbcadea-0a6a-42b6-bb14-8fa6bb5a2e00",
        link: "/classified-records",
      },
      // {
      //   title: "Completed Records",
      //   permission: "fa93ebcd-ad5b-478f-b3d3-75d98b577e4e",
      //   link: "/completed-records",
      // },
      {
        title: "Ready for Dispatch",
        permission: "d3e0435f-8a1d-44fa-88a7-a6fd25efe130",
        link: "/ready-for-dispatch",
      },
      {
        title: "Records Archive",
        permission: "9a213ac6-a299-4a21-aa86-570fc2b7864f",
        link: "/archieved-records",
      },
    ],
  },

  {
    title: "Task & Assignments",
    icon: <FaClipboardCheck className="text-xl" />,
    permission: "216a3f37-aa11-413f-8732-8e781077ba4c",
    children: [
      {
        title: "Task Management",
        permission: "a416cb37-d61b-488e-a852-ea3e2209924f",
        link: "/task-management/task-list",
      },
      {
        title: "Task board",
        permission: "6a5f1707-c991-42e2-a0fc-f16e870352e6",
        link: "/task-management/task-board",
      },
      {
        title: "Memo / Instruction",
        permission: "b105fb13-734a-4f62-a9a7-4aea6a19fb49",
        link: "/memos",
      },
    ],
  },

  {
    title: "Incident & Case",
    icon: <FaBookSkull className="text-xl" />,
    permission: "a6b48ae7-18a5-434c-a942-ddd270e02d7c",
    link: "#!",

    children: [
      {
        title: "Station Diary",
        permission: "182da9dd-276f-4644-9c15-1c0d67113321",
        link: "/station-diary",
      },
      {
        title: "Incident Management",
        permission: "f7bd6710-6cee-454a-9fb3-fe4f53bea95f",
        link: "/incident-management",
      },
      {
        title: "Arrest & Bookings",
        permission: "684168e5-c523-435b-81a9-54946e4ba67b",
        link: "/arrest-bookings",
      },
      {
        title: "Bail & Sortee",
        permission: "c57a0a78-cf81-45fa-a41e-7ffaa8dd20eb",
        link: "/bail-sortee",
      },
      // {
      //   title: "Manage Offender",
      //   permission: "3f8cb55f-ac16-4c67-8a4a-765afdf7d02a",
      //   link: "/manage-offender",
      // },
      // {
      //   title: "Manage Evidence",
      //   permission: "02f64696-1983-4b72-b4d1-0ade82f67007",
      //   link: "/manage-evidence",
      // },
    ],
  },

  {
    title: "Operations",
    icon: <FaPeopleCarryBox className="text-xl" />,
    permission: "1806ccd8-90f1-449a-bffe-d463735c7d64",
    children: [
      {
        title: "Investigation / Intelligence",
        permission: "cb80e5a0-0f30-4982-9e21-1d08cb1f76b7",
        link: "/operations/investigations",
      },
      {
        title: "Police Stations",
        permission: "c4910c58-a4c0-4096-a766-6d4df5836768",
        link: "/operations/police-stations",
      },
      {
        title: "Wanted / POI",
        permission: "d0c8f648-1ee5-4a55-82c8-ff01ae1568b2",
        link: "/wanted",
      },
      {
        title: "Operations / Mission",
        permission: "4f09043c-c6d2-458b-9cb1-220484fab29f",
        link: "/operations/missions",
      },
    ],
  },
  {
    title: "Reports",
    icon: <FaTableCells className="text-xl" />,
    permission: "930f7a1e-46c7-4d14-b7a3-0db2f4ef804b",
    link: "#!",
    children: [
      {
        title: "Ops Report",
        permission: "be00de8b-9a93-4483-afc7-7c69e0243b5c",
        link: "/operations/reports/ops-report",
      },
      {
        title: "Incident Report",
        permission: "076af15f-c739-4129-8e9f-5d593f2fe500",
        link: "/operations/reports/incident-report",
      },
      {
        title: "Arrest Report",
        permission: "1429bcfb-d9e9-43e3-9441-18e97fe7e7a0",
        link: "/operations/reports/arrest-report",
      },
      {
        title: "Incident Activity Report",
        permission: "1f883d0a-9184-4ef5-91a5-af3a314134c9",
        link: "/operations/reports/incident-activity-report",
      },
    ],
  },
  {
    icon: <FaMessage className="text-xl" />,
    title: "Chat",
    permission: "f86afbb0-302e-4f7d-a73a-445ddde0a856",
    link: "/chat",
  },
  {
    title: "System Management",
    icon: <FaComputer className="text-xl" />,
    permission: "fd8fe252-bb72-41d6-ac32-3ebafa728e38",
    children: [
      {
        title: "Location Management",
        permission: "f43c7b8d-7029-4f4c-85fe-8e6a6866f577",
        link: "/location-management",
      },
      {
        title: "Staff Management",
        permission: "30f9f6bd-5ed2-45b8-b495-bd563d191306",
        link: "/users",
      },
      {
        title: "Group Management",
        permission: "dfe53595-89cf-48d2-ac00-9815c0cb1eff",
        link: "/group-management",
      },
      {
        title: "Roles and Permissions",
        permission: "62bdde58-4084-4cd4-b5e6-4092e49f18a5",
        link: "/roles-and-permissions",
      },
      {
        title: "Priority Levels",
        permission: "dfe53595-89cf-48d2-ac00-9815c0cb1eff",
        link: "/priority-levels",
      },
      {
        title: "Routing Rule Actions",
        permission: "601fa10a-fe4e-4f92-a354-e7a5f303c624",
        link: "/actions",
      },
      {
        title: "Statute",
        permission: "601fa10a-fe4e-4f92-a354-e7a5f303c624",
        link: "/statute",
      },
      {
        title: "Routing Rule Management",
        permission: "b534edc7-0ff7-4c63-a19f-ab0f37757583",
        link: "/settings",
      },
      {
        title: "Record Type Management",
        permission: "42525454-e59b-4cff-946c-2a35c0a8e8dc",
        link: "/record-types",
      },
      {
        title: "Logs",
        permission: "4466e749-4313-40a3-a992-4b6b5889b80d",
        link: "/logs",
      },

      {
        title: "Rank & Regiment Management",
        permission: "517724aa-5c1b-4fbb-81d8-ab1a8d120514",
        link: "/ranks",
      },
      {
        title: "Incident Type Management",
        permission: "5c6ff03c-1e4a-4371-9c27-bef089cc0e39",
        link: "/incident-type",
      },
    ],
  },
  {
    icon: <FaQuestionCircle className="text-xl" />,
    title: "Help & FAQ",
    permission: "f86afbb0-302e-4f7d-a73a-445ddde0a856",
    link: "/faq",
  },

  // // // Human Resources
  // // {
  // //   title: "Human Resource",
  // permission: "",//
  //  link: "#!",
  // //   icon: <FaPerson className="text-xl" />,
  // //   children: [
  // //     {
  // //       title: "Employee Management",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //     {
  // //       title: "Leave Management",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //     {
  // //       title: "Performance Management",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //     {
  // //       title: "HR Policies",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //   ],
  // // },

  // // Finance
  // // {
  // //   title: "Finance Management",
  // permission: "",//
  //  link: "#!",
  // //   icon: <FaTableCells className="text-xl" />,
  // //   children: [
  // //     {
  // //       title: "Budgeting",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //     {
  // //       title: "Expense Management",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //     {
  // //       title: "Income Tracking",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //     {
  // //       title: "Payroll",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //   ],
  // // },

  // // {
  // //   title: "Procument & Logistics",
  // permission: "",//
  //  link: "#!",
  // //   icon: <FaTruckFast className="text-xl" />,
  // //   children: [
  // //     {
  // //       title: "Procurement Management",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //     {
  // //       title: "Inventory Management",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //     {
  // //       title: "Supplier Managemen",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //     {
  // //       title: "Import/Export Management",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //     {
  // //       title: "Quality Control",
  // permission: "",//
  //  link: "#!",
  // //     },
  // //   ],
  // // },

  // // procument
  // // {
  // //   title: "Procument Management",
  // permission: "",//
  //  link: "#!",
  // //   icon: <FaPeopleArrows className="text-xl" />,
  // // },

  // // // logistics
  // // {
  // //   title: "Logistics Management",
  // permission: "",//
  //  link: "#!",
  // //   icon: <FaTruckFast className="text-xl" />,
  // // },

  // // // audit
  // // {
  // //   title: "Audit",
  // permission: "",//
  //  link: "#!",
  // //   icon: <FaBook className="text-xl" />,
  // // },

  // // // Inventory
  // // {
  // //   title: "Inventory / Stock",
  // permission: "",//
  //  link: "#!",
  // //   icon: <FaStore className="text-xl" />,
  // // },

  // // // QA
  // // {
  // //   title: "Quality Assurance",
  // permission: "",//
  //  link: "#!",
  // //   icon: <FaCertificate className="text-xl" />,
  // // },
];

export default menus;
