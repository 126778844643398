import React from "react";
import CustomSpinner from "./CustomSpinner";

const IsLoading = () => {
  return (
    <div className="h-[400px] flex items-center justify-center">
      <CustomSpinner />
    </div>
  );
};

export default IsLoading;
