import React from "react";
import { FaX } from "react-icons/fa6";

const CustomCloseButton = ({ setIsOpen }) => {
  return (
    <button
      onClick={() => setIsOpen(false)}
      className="rounded-md bg-main/80 text-white p-2"
    >
      <FaX />
    </button>
  );
};

export default CustomCloseButton;
