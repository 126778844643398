import React from "react";
import { uuidV4 } from "../../../../../helpers/helperFunctions";

const MissionAssignedTo = ({ missionDetails }) => {
  return (
    <div className="flex flex-col gap-2">
      {missionDetails?.missionGroups?.map((group, idx) => (
        <p className="font-semibold flex items-center gap-5" key={uuidV4()}>
          <span>{idx + 1}.</span> <span>{group?.name}</span>
        </p>
      ))}
    </div>
  );
};

export default MissionAssignedTo;
