import React, { useState } from "react";
import AddCommentToMissionModal from "./AddCommentToMissionModal";
import IsNoData from "../../../../../components/generic/IsNoData";
import {
  confirmationSweetAlert,
  errorToast,
  uuidV4,
} from "../../../../../helpers/helperFunctions";
import moment from "moment";
import { FaTrash } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useDeleteMissionComment } from "../../missionsQueries";
import { useQueryClient } from "@tanstack/react-query";

const MissionDetailsComments = ({ missionDetails }) => {
  const [openAddCommentModal, setOpenAddCommentModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const deleteCommentMutation = useDeleteMissionComment();
  const queryClient = useQueryClient();

  const handleDelete = async (commentId) => {
    try {
      const { isConfirmed } = await confirmationSweetAlert();

      if (isConfirmed) {
        await deleteCommentMutation.mutateAsync(commentId, {
          onSuccess: () => {
            // successToast("Comment deleted successfully");
            queryClient.invalidateQueries(["getSingleMission"]);
          },
          onError: (err) => {
            errorToast(
              err?.response?.data?.message ||
                "Server error: cannot delete comment"
            );
          },
        });
      }
    } catch (err) {}
  };

  return (
    <div>
      <AddCommentToMissionModal
        isOpen={openAddCommentModal}
        setIsOpen={setOpenAddCommentModal}
        missionId={missionDetails?.id}
      />
      <div className="flex items-center justify-end mb-2">
        <button
          onClick={() => setOpenAddCommentModal(true)}
          className="p-1 my-2 px-3 rounded-md bg-blue-500 text-white text-sm"
        >
          Add Comment
        </button>
      </div>
      {missionDetails?.missionComments?.length === 0 ? (
        <IsNoData message="No comments. Add comments" />
      ) : (
        <div className="flex flex-col gap-3">
          {missionDetails?.missionComments?.map((comment) => (
            <div key={uuidV4()} className="flex gap-3">
              <div className="flex-1">
                <div className="flex flex-col gap-2 border-b border-gray-200 p-1">
                  <h6 className="font-black flex items-center gap-2">
                    {comment?.comment}
                  </h6>
                  <div className="flex items-center gap-5">
                    <p className="text-gray-500">
                      {moment(comment?.createdAt).format("DD-MM-YYYY")}
                    </p>
                    {/* <button
                      className="text-sm"
                      onClick={() => navigate("/users/details")}
                    >
                      {`(${comment.userDocumentComment.firstName} ${comment.userDocumentComment.middleName} ${comment.userDocumentComment.lastName})`}
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                {comment?.userId === user?.id && (
                  <button
                    onClick={() => handleDelete(comment?.id)}
                    className="p-2 text-red-500"
                  >
                    <FaTrash />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MissionDetailsComments;
