import React from "react";
import { twMerge } from "tailwind-merge";

const CustomTextArea = ({
  id,
  label,
  required,
  placeholder,
  error,
  className,
  rows,
  ...rest
}) => {
  return (
    <div className="">
      {label && (
        <label htmlFor={id} className="block text-sm text-main mb-2">
          {label} {required && <sup className="text-red-500">*</sup>}
        </label>
      )}
      <div>
        <textarea
          id={id}
          rows={rows}
          placeholder={placeholder}
          className={twMerge(
            "text-sm border border-gray-400 p-2 outline-none focus:border-main w-full rounded-lg",
            error && "border-red-500 focus:border-red-500",
            className
          )}
          {...rest}
        />
      </div>
      {error && <small className="text-red-500 text-sm my-1">{error}</small>}
    </div>
  );
};

export default CustomTextArea;
