import React, { useState } from "react";
import Modal from "react-responsive-modal";
import CustomFileUpload from "../../../../../components/generic/CustomFileUpload";
import { FaUpload } from "react-icons/fa6";
import {
  errorToast,
  successToast,
  uuidV4,
} from "../../../../../helpers/helperFunctions";
import CustomButton from "../../../../../components/generic/CustomButton";
import axiosConfig from "../../../../../http-common";
import CustomCloseButton from "../../../../../components/generic/CustomCloseButton";
import { useQueryClient } from "@tanstack/react-query";
import { useUploadAttachment } from "../../missionsQueries";
import CustomProgressBar from "../../../../../components/generic/CustomProgressBar";

const FileUploadModal = ({ isOpen, setIsOpen, missionId }) => {
  const [files, setFiles] = useState(null);
  const queryClient = useQueryClient();
  const uploadAttachmentMutation = useUploadAttachment();

  const handleUpload = async () => {
    try {
      const formData = new FormData();

      files.forEach((file) => {
        formData.append(uuidV4(), file);
      });
      formData.append("missionId", missionId);

      await uploadAttachmentMutation.mutateAsync(formData, {
        onError: (err) => {
          errorToast(
            err?.response?.data?.message ||
              "Server error: cannot upload attachment"
          );
        },
        onSuccess: () => {
          queryClient.invalidateQueries(["getSingleMission"]);
          successToast("File uploaded successfully");
          setFiles(null);
          setIsOpen(false);
        },
      });
    } catch (err) {}
  };
  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setIsOpen(!isOpen);
      }}
      center
      classNames={{
        modal: "w-[500px] min-h-[300px]",
      }}
      showCloseIcon={false}
    >
      <div className="flex items-center justify-between mb-10">
        <h2 className="text-xl uppercase">Upload file</h2>
        <CustomCloseButton setIsOpen={setIsOpen} />
      </div>
      <div className=" p-2">
        <CustomFileUpload
          setFiles={(files) => setFiles(files)}
          isMutiple={true}
        />

        {(!files || files?.length === 0) && (
          <small className="text-red-500 text-sm my-2">
            At least one file is required
          </small>
        )}

        <CustomButton
          isDisabled={!files || files?.length === 0}
          className={"flex items-center gap-3"}
          onClick={handleUpload}
        >
          <FaUpload className="" /> Upload files
        </CustomButton>
      </div>
      {uploadAttachmentMutation.isPending && <CustomProgressBar />}
    </Modal>
  );
};

export default FileUploadModal;
