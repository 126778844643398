import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGetSingleMission } from "../missionsQueries";
import BackBtnWithPageTitle from "../../../../components/generic/BackBtnWithPageTitle";
import MissionDetailsHeader from "./components/MissionDetailsHeader";
import MissionDetailsOverview from "./components/MissionDetailsOverview";
import MissionDetailsAttachments from "./components/MissionDetailsAttachments";
import MissionDetailsComments from "./components/MissionDetailsComments";
import IsLoading from "../../../../components/generic/IsLoading";
import IsError from "../../../../components/generic/isError";
import { Fade } from "react-awesome-reveal";
import MissionAssignedTo from "./components/MissionAssignedTo";

const MissionDetailsPage = () => {
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = useState("overview");
  const [id, setId] = useState(state?.id);
  const getSingleMissionQuery = useGetSingleMission(id);
  console.log(state);
  useEffect(() => {
    if (state) setId(state?.id);
  }, [state]);
  return (
    <div className="h-full overflow-hidden flex flex-col">
      <div className="flex items-center justify-between p-2">
        <BackBtnWithPageTitle title={"Mission  Details"} />
        <div className="flex items-center gap-4"></div>
      </div>

      {/* Header */}
      <MissionDetailsHeader
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        missionDetails={getSingleMissionQuery.data?.data || {}}
      />

      <div className="flex-1 overflow-auto scrollbar-thin">
        {getSingleMissionQuery?.isPending ? (
          <IsLoading />
        ) : getSingleMissionQuery.isError ? (
          <IsError
            message={getSingleMissionQuery.error?.response?.data?.message}
          />
        ) : (
          <Fade className="w-full h-full p-10">
            {currentTab === "overview" && (
              <MissionDetailsOverview
                missionDetails={getSingleMissionQuery.data?.data || {}}
              />
            )}
            {currentTab === "attachments" && (
              <MissionDetailsAttachments
                missionDetails={getSingleMissionQuery.data?.data || {}}
              />
            )}

            {currentTab === "comments" && (
              <MissionDetailsComments
                missionDetails={getSingleMissionQuery.data?.data || {}}
              />
            )}

            {currentTab === "assignedGroups" && (
              <MissionAssignedTo
                missionDetails={getSingleMissionQuery.data?.data || {}}
              />
            )}
          </Fade>
        )}
      </div>
    </div>
  );
};

export default MissionDetailsPage;
