import { createSlice } from "@reduxjs/toolkit";


const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: sessionStorage.getItem("police-admin-user") ? JSON.parse(sessionStorage.getItem("police-admin-user")) : null,
    token: sessionStorage.getItem("police-admin-token") ? JSON.parse(sessionStorage.getItem("police-admin-token")) : null
  },
  reducers: {
    loginUserAction: (state, action) => {
      state.user = action.payload.data;
      state.token = action.payload.token
      sessionStorage.setItem("police-admin-user", JSON.stringify(action.payload.data || {}))
      sessionStorage.setItem("police-admin-token", JSON.stringify(action.payload.token || ""))

    },
    logoutUserAction: (state) => {
      state.user = null;
      state.token = null
      sessionStorage.removeItem("police-admin-user")
      sessionStorage.removeItem("police-admin-token")
    }
  }
})

export const { loginUserAction, logoutUserAction } = authSlice.actions

export default authSlice.reducer