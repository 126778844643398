import { useQuery } from "@tanstack/react-query";
import axiosConfig from "./http-common";
import { store } from "./redux/store";
import { loginUserAction } from "./redux/slices/authSlice";



export const useVerifyUser = (path) => useQuery({
  retry: 1,
  queryKey: ["verifyUser", { path }],
  queryFn: async () => {
    try {
      const data = await axiosConfig.get("/auth/verifyMe")
      const token = store.getState().auth.token
      store.dispatch(loginUserAction({
        data: data?.user,
        token
      }))

      return data?.user

    } catch (err) {

      return null
    }
  },
  refetchInterval: 60 * 1000
})