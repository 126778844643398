export const mainColor = "#100160"
export const mainColorLight = "#002870"

export const tailwindColors = [
  "bg-gray-500",
  "bg-red-500",
  "bg-orange-500",
  "bg-yellow-500",
  "bg-green-500",
  "bg-teal-500",
  "bg-blue-500",
  "bg-indigo-500",
  "bg-purple-500",
  "bg-pink-500",
];


export const getRandomTailwindColor = () => tailwindColors[Math.floor(Math.random() * tailwindColors.length)]
