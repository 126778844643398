import React from "react";
import Drawer from "react-modern-drawer";
import { FaBell, FaLock, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { lockScreen, logoutUser } from "../../helpers/helperFunctions";
import CustomCloseButton from "../generic/CustomCloseButton";

const ProfileCanvas = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  return (
    <Drawer
      size={350}
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      direction="right"
      className="rounded-md p-5"
    >
      <div className="flex items-stretch justify-between mb-2">
        <div className="flex items-stretch gap-2">
          <img
            src="/images/profile.png"
            className="w-14 h-14 object-scale-down rounded-full"
            alt="PROFILE"
          />
          <div>
            <p className="text-sm font-bold text-black">
              {user?.username || ""}
            </p>
            <p className="text-main">
              {user?.firstName || ""} {user?.lastName || ""}
            </p>
          </div>
        </div>

        <div>
          <CustomCloseButton setIsOpen={setIsOpen} />
        </div>
      </div>

      <hr />
      <div className="mt-2">
        <button
          onClick={() => navigate("/profile")}
          className="flex items-center gap-3 w-full py-2 hover:bg-gray-100 rounded-md px-1 text-main"
        >
          <FaUserCircle className="text-xl" /> <span>Your Profile</span>
        </button>
        {/* <button
          onClick={() => navigate("/memos")}
          className="flex items-center justify-between gap-3 w-full py-2 hover:bg-gray-100 rounded-md px-1 text-main"
        >
          <div className="flex items-center gap-3">
            <FaBell className="text-xl" /> <span>Notifications</span>
          </div>
          <div className="rounded-md text-sm p-1 px-5 bg-orange-600 text-white">
            3
          </div>
        </button> */}

        {/* <button
          onClick={() => lockScreen(navigate)}
          className="flex items-center gap-3 w-full py-2 hover:bg-gray-100 rounded-md px-1 text-main"
        >
          <FaLock className="text-xl" /> <span>Lock screen</span>
        </button> */}
        <button
          onClick={() => logoutUser(navigate)}
          className="flex items-center gap-3 w-full py-2 hover:bg-gray-100 rounded-md px-1 text-main"
        >
          <FaSignOutAlt className="text-xl" /> <span>Sign Out</span>
        </button>
      </div>
    </Drawer>
  );
};

export default ProfileCanvas;
