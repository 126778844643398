import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProfileCanvas from "./ProfileCanvas";
import { systemName } from "../../helpers/constants/system";
import { FaBell } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const CustomHeader = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="bg-main text-white p-2 flex items-center justify-between px-5">
      <div>{systemName}</div>
      <div className="flex items-center gap-5">
        {/* <button
          onClick={() => navigate("/memos")}
          className="border-0 outline-0 relative"
        >
          <FaBell className="text-3xl" />

          <div className="absolute -top-1 -right-1 w-5 h-5 rounded-full bg-orange-500 text-white flex items-center justify-center text-sm">
            3
          </div>
        </button> */}
        <button
          onClick={() => setOpenDrawer(true)}
          className="border-0 outline-0"
        >
          <img
            src="/images/profile.png"
            className="w-10 h-10 object-scale-down rounded-full"
            alt="PROFILE"
          />
        </button>

        <ProfileCanvas isOpen={openDrawer} setIsOpen={setOpenDrawer} />
      </div>
    </div>
  );
};

export default CustomHeader;
