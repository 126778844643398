import React, { useEffect, useState } from "react";
import CustomSidebar from "./CustomSidebar";
import CustomHeader from "./CustomHeader";
import PageWrapper from "./PageWrapper";
import { useVerifyUser } from "../../genericQueries";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { errorSweetAlert, logoutUser } from "../../helpers/helperFunctions";
import { useSelector } from "react-redux";
import { useIdle } from "react-use";

const MainLayout = ({ children, title, parentPath }) => {
  const { pathname } = useLocation();
  const [currentPath, setCurrentPath] = useState(pathname);
  useVerifyUser(currentPath);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isIdle = useIdle(5 * 60 * 1000);

  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    setCurrentPath(pathname);
    queryClient.invalidateQueries(["verifyUser"]);
    if (user?.shouldSetPassword) {
      errorSweetAlert(
        "Your password has not been changed since your registration. Please set a new password. This is a security measure to ensure only authorized accesses."
      );
      navigate("/profile");
    }
  }, [pathname]);

  useEffect(() => {
    if (isIdle) {
      errorSweetAlert(
        "We are logging you out because your have been inactive for 5 minutes. This is a security measure to ensure only authorized accesses."
      );
      setTimeout(() => {
        logoutUser(navigate);
      }, 1000);
    }
  }, [isIdle]);
  return (
    <PageWrapper title={title}>
      <div className="h-screen w-screen flex items-stretch bg-white text-black overflow-hidden">
        {/* sidebar */}
        <CustomSidebar parentPath={parentPath} />

        {/* right layout */}
        <div className="flex-1 flex flex-col">
          <CustomHeader />
          <div className="flex-1 overflow-x-hidden overflow-y-auto scrollbar-thin ">
            {children}
          </div>
          {/* <CustomFooter /> */}
        </div>
      </div>
    </PageWrapper>
  );
};

export default MainLayout;
