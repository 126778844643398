import React, { useState } from "react";
import FileUploadImage from "../../assets/icons/upload.png";
import { v4 as uuidV4 } from "uuid";
import classNames from "classnames";
import { FaTrash } from "react-icons/fa6";
import { useCallback } from "react";
const CustomFileUpload = ({
  setFiles = () => null,
  className,
  showUploadedFileNames = true,
  acceptedFiles = "*/*",
  isMutiple = true,
}) => {
  const id = uuidV4();
  const [localFiles, setLocalFiles] = useState([]);
  const handleFileDelete = (idx) => {
    const filesCopy = [...localFiles].filter((_, i) => i !== idx);

    setLocalFiles(filesCopy);
    setFiles(isMutiple ? filesCopy : filesCopy[0]);
  };

  return (
    <div
      className={classNames(
        "min-w-[400px] max-w-full rounded-lg overflow-hidden m-4",
        className
      )}
    >
      <label
        htmlFor={id}
        className="block border-dotted border-4 p-3 border-main/30 cursor-pointer"
      >
        <img
          alt=""
          src={FileUploadImage}
          className="block w-full h-40 object-scale-down mx-auto opacity-60"
        />
        <p className="text-center text-main/70 my-3">
          Click on the box to choose files.
        </p>
      </label>

      {/* Show uploaded files */}
      {showUploadedFileNames && localFiles?.length > 0 && (
        <div className="py-2 flex flex-col gap-2">
          <p className="text font-bold uppercase my-4">Uploaded files</p>
          {localFiles.map((file, idx) => (
            <div
              key={uuidV4()}
              className="flex items-center justify-between gap-2"
            >
              <div className="flex-1">
                <b>{idx + 1}.</b> {file?.name}
              </div>
              <div className="flex items-center gap-2">
                <button onClick={() => handleFileDelete(idx)}>
                  <FaTrash className="text-red-500" />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* End of show uploaded files */}

      {/* Input hidden with the hidden class */}
      <input
        className="hidden"
        id={id}
        type="file"
        accept={acceptedFiles}
        multiple={isMutiple}
        onChange={(e) => {
          console.log(e.target.files);
          setLocalFiles(
            isMutiple ? [...localFiles, ...e.target.files] : [e.target.files[0]]
          );
          setFiles(
            isMutiple ? [...localFiles, ...e.target.files] : e.target.files[0]
          );
        }}
      />
    </div>
  );
};

export default CustomFileUpload;
