import axios from "axios";
import { backendUrl } from "./helpers/constants/system";
import { store } from "./redux/store";
import { logoutUserAction } from "./redux/slices/authSlice";
import { errorSweetAlert, errorToast } from "./helpers/helperFunctions";

/**
 * THANK YOU CHATGPT
 */

// Create a new axios instance
const axiosConfig = axios.create({
  baseURL: backendUrl, // Set your API base URL
  timeout: 60000, // Set timeout to 60 seconds
  headers: {
    "Content-Type": "application/json", // Default content type
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

// Request interceptor for adding authorization token or any other request manipulation
axiosConfig.interceptors.request.use(
  (config) => {
    // Add logic here to manipulate request config if needed, like adding authorization token
    const { token } = store.getState().auth;

    config.headers.Authorization = `Bearer ${token || ""}`;
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

const notAuthorizedMessages = [
  "Access denied, you're not login",
  "Access denied, user not found",
  "Access denied, invalid token",
  "Role not found for the user",
  "User doesn't have permission to this route",
  "User not found",

];

// Response interceptor for handling response errors globally
axiosConfig.interceptors.response.use(
  (response) => {
    // Return response data
    return response.data;
  },
  (error) => {
    // Handle response errors
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error(
        "Response error:",
        error.response.status,
        error.response.data
      );


      if (notAuthorizedMessages.includes(error.response?.data?.message?.message || error.response?.data?.message)) {
        store.dispatch(logoutUserAction());
        errorSweetAlert(error.response?.data?.message?.message || error.response?.data?.message || "Session expired. Please login again.`");
        setTimeout(() => {
          window.location.href = "/";
        }, [500]);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Request error:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error:", error.message);
    }
    return Promise.reject({
      ...error,
      response: {
        ...error?.response,
        data: {
          ...error.response?.data,
          message: error?.response?.data?.message?.message
            ? error?.response?.data?.message?.message
            : error?.response?.data?.message ||
            "Server error: please contact system admin",
        },
      },
    }); // Return Promise.reject() to propagate the error further
  }
);

export default axiosConfig;
