import classNames from "classnames";
import React, { useState } from "react";
import { useCollapse } from "react-collapsed";
import { FaChevronDown, FaChevronRight } from "react-icons/fa6";
import Popup from "reactjs-popup";
import { twMerge } from "tailwind-merge";

const CustomSidebarCollapse = ({
  renderChildMenus,
  isCollapsed,
  menu,
  parentPath,
  className,
}) => {
  const [expandedMenus, setExpandedMenus] = useState(
    sessionStorage.getItem("expandedMenus")
      ? JSON.parse(sessionStorage.getItem("expandedMenus"))
      : {}
  );
  const [isExpanded, setExpanded] = useState(
    expandedMenus[menu.title] ? true : false
  );

  // console.log(expandedMenus[menu.title]);

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: expandedMenus[menu.title] ? true : false,
  });

  return (
    <div>
      {isCollapsed ? (
        <Popup
          trigger={(open) => (
            <button
              className={twMerge(
                "text-gray-400 font-light p-3 hover:text-gray-300 w-full flex items-center gap-3 text-lg",
                menu.children.find((childMenu) =>
                  childMenu.link.includes(parentPath)
                ) && "text-white",
                className
              )}
            >
              {menu.icon}
              {!isCollapsed && menu.title}{" "}
              {isCollapsed && <FaChevronRight className="text-sm" />}
            </button>
          )}
          position="right center"
          closeOnDocumentClick
          className="min-w-[200px]"
        >
          <div className="bg-main">{renderChildMenus(menu)}</div>
        </Popup>
      ) : (
        <>
          <button
            className={twMerge(
              "text-gray-400 font-light p-3 hover:text-gray-300 w-full flex items-center gap-3 text-lg cursor-pointer justify-between",
              menu.children.find((childMenu) =>
                childMenu.link.includes(parentPath)
              ) && "text-white",
              className
            )}
            {...getToggleProps({
              onClick: () => {
                const expandedMenusCopy = {
                  ...expandedMenus,
                };

                if (isExpanded) {
                  delete expandedMenusCopy[menu.title];
                  sessionStorage.setItem(
                    "expandedMenus",
                    JSON.stringify(expandedMenusCopy)
                  );
                  setExpandedMenus(expandedMenusCopy);
                } else {
                  sessionStorage.setItem(
                    "expandedMenus",
                    JSON.stringify({
                      ...expandedMenus,
                      [menu.title]: menu.title,
                    })
                  );
                  setExpandedMenus({
                    ...expandedMenus,
                    [menu.title]: menu.title,
                  });
                }

                setExpanded((prevExpanded) => !prevExpanded);
              },
            })}
          >
            <div className={twMerge("flex items-center gap-3", className)}>
              {menu.icon}
              {!isCollapsed && menu.title}{" "}
            </div>
            {isCollapsed ? (
              <FaChevronRight className="text-sm" />
            ) : !isExpanded ? (
              <FaChevronRight className="text-sm" />
            ) : (
              <FaChevronDown className="text-sm" />
            )}
          </button>
          <div {...getCollapseProps()}>{renderChildMenus(menu)}</div>
        </>
      )}
    </div>
  );
};

export default CustomSidebarCollapse;
