import React from "react";
import "./CustomProgressBar.css";

const CustomProgressBar = () => {
  return (
    <div className="w-full p-[0.3px]">
      <span class="progressBar"></span>
    </div>
  );
};

export default CustomProgressBar;
