import moment from "moment";
import React from "react";
import { twMerge } from "tailwind-merge";

const MissionDetailsHeader = ({
  currentTab,
  setCurrentTab,
  missionDetails,
}) => {
  return (
    <div className="p-10 pb-0 bg-orange-50 flex flex-col gap-4">
      <div className="flex items-stretch">
        <div className="flex-1 px-7 py-4">
          <h2 className="font-bold text-xl mb-2 flex items-center gap-3 justify-between">
            <span>{missionDetails?.title || "N/A"}</span>{" "}
          </h2>
          <div className="flex items-center gap-3 mb-2">
            <span>Created at: </span>{" "}
            <span className="font-semibold">
              {moment(missionDetails?.createdAt).format("DD MMM, YYYY")}
            </span>
          </div>

          {/* <div className="flex items-center gap-3">
            <span>Created By: </span>{" "}
            <span className="font-semibold">
              {missionDetails?.senderMemo?.fullName || "N/A"}
            </span>
          </div> */}
        </div>
      </div>

      <div className="flex items-center">
        <button
          onClick={() => setCurrentTab("overview")}
          className={twMerge(
            "text-main py-2 px-7 font-semibold outline-none border-b-2 border-transparent",
            currentTab === "overview" && "border-b-2 border-main"
          )}
        >
          Overview
        </button>

        <button
          onClick={() => setCurrentTab("attachments")}
          className={twMerge(
            "text-main py-2 px-7 font-semibold outline-none border-b-2 border-transparent",
            currentTab === "attachments" && "border-b-2 border-main"
          )}
        >
          Attachments
        </button>

        <button
          onClick={() => setCurrentTab("comments")}
          className={twMerge(
            "text-main py-2 px-7 font-semibold outline-none border-b-2 border-transparent",
            currentTab === "comments" && "border-b-2 border-main"
          )}
        >
          Comments
        </button>

        <button
          onClick={() => setCurrentTab("assignedGroups")}
          className={twMerge(
            "text-main py-2 px-7 font-semibold outline-none border-b-2 border-transparent",
            currentTab === "assignedGroups" && "border-b-2 border-main"
          )}
        >
          Assigned Groups
        </button>
      </div>
    </div>
  );
};

export default MissionDetailsHeader;
