import React, { useEffect } from "react";
import Modal from "react-responsive-modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAddCommentToMission } from "../../missionsQueries";
import { useQueryClient } from "@tanstack/react-query";
import CustomCloseButton from "../../../../../components/generic/CustomCloseButton";
import CustomTextArea from "../../../../../components/generic/CustomTextArea";
import CustomButton from "../../../../../components/generic/CustomButton";
import CustomProgressBar from "../../../../../components/generic/CustomProgressBar";
import {
  errorToast,
  successToast,
} from "../../../../../helpers/helperFunctions";

const AddCommentToMissionModal = ({ isOpen, setIsOpen, missionId }) => {
  const createCommentMutation = useAddCommentToMission();
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      missionId: "",
      comment: "",
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        await createCommentMutation.mutateAsync(values, {
          onError: (err) => {
            errorToast(
              err?.response?.data?.message ||
                "Server error: cannot create comment"
            );
          },
          onSuccess: () => {
            queryClient.invalidateQueries(["getSingleMission"]);
            successToast("Comment successfully added");
            formikHelpers.resetForm();
            setIsOpen(false);
          },
        });
      } catch (error) {}
    },
    validationSchema: Yup.object().shape({
      comment: Yup.string().required("Required"),
    }),
  });

  useEffect(() => {
    if (missionId)
      formik.setValues({
        ...formik.values,
        missionId,
      });
  }, [missionId]);

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      center
      classNames={{
        modal: "w-[400px] flex flex-col gap-2",
      }}
      showCloseIcon={false}
    >
      <div className="flex items-center justify-between mb-3">
        <h6 className="font-bold">Add Comment</h6>

        <CustomCloseButton setIsOpen={setIsOpen} />
      </div>
      <div className="flex-1">
        <CustomTextArea
          id={"comment"}
          value={formik.values.comment}
          onChange={formik.handleChange}
          placeholder={"Enter comment"}
          label={"Comment"}
          required={true}
          className={"h-[170px]"}
          error={formik.errors.comment}
        />
      </div>

      <div className="flex items-center justify-end gap-2 mt-4">
        <CustomButton
          isDisabled={formik.isSubmitting}
          onClick={formik.submitForm}
        >
          {formik.isSubmitting ? "Adding..." : "Add comment"}
        </CustomButton>
        <button onClick={() => setIsOpen(false)} className="p-2 text-sm">
          Cancel
        </button>
      </div>
      {createCommentMutation?.isPending && <CustomProgressBar />}
    </Modal>
  );
};

export default AddCommentToMissionModal;
